import { IComputerPrice, ICreateBooking } from '@/types/interface';
import { apiClient } from './apiService';

export const bookingService = {
  computePrice(data: IComputerPrice) {
    return apiClient.request({
      method: 'POST',
      url: '/mobileCustomer/web_compute_price',
      data,
    });
  },

  getPromotionDetail(code: string, collaboratorId?: string | null) {
    return apiClient.request({
      method: 'GET',
      url: `/mobileCustomer/find_by_code_promotion/${code}`,
      params:
        collaboratorId !== null && collaboratorId !== ''
          ? { collaboratorId }
          : undefined,
    });
  },

  getPhoneDetail(data: { dialCode: string; phone: string }) {
    return apiClient.request({
      method: 'POST',
      url: `/mobileCustomer/find_by_phone`,
      data,
    });
  },

  createBooking(data: ICreateBooking) {
    return apiClient.request({
      method: 'POST',
      url: '/mobileCustomer/web_create_taxi',
      data,
    });
  },

  createOrderFromPartnerQR(collaboratorId: string, data: ICreateBooking) {
    return apiClient.request({
      method: 'POST',
      url: `/partner/${collaboratorId}/create_order_from_qr`,
      data,
    });
  },

  getDetailBooking(code: string) {
    return apiClient.request({
      method: 'GET',
      url: `/mobileCustomer/find_detail_by_code/${code}`,
    });
  },

  getDriverQR(data: { id: string }) {
    return apiClient.request({
      method: 'POST',
      url: `/mobileCustomer/check_qr_order`,
      data,
    });
  },

  getPublicHistory(data: { phone: string; dialCode: string }) {
    return apiClient.request({
      method: 'POST',
      url: `/mobileDriver/get_list_orders_by_phone`,
      data,
    });
  },
};
